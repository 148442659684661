.rpb-cards {
  .card {
    border-radius:0px;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
    .img-fluid {
      min-height:200px;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }

  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0rem;
          @include media-breakpoint-up(xl) {
            left:-2rem;
          }
        }
        .splide__arrow--next {
          right:0rem;
          @include media-breakpoint-up(xl) {
            right:-2rem;
          }
        }
      }
      .splide__track {
        margin-left:1.5rem;
        margin-right:1.5rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}
