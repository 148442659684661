.offcanvas {
  background-attachment:fixed!important;
  .navbar-nav {
    .nav-link {
      color:white;
      font-weight:normal;
      padding-top:1rem;
      padding-bottom:1rem;
    }
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      background:transparent;
      border:0;
      border-radius:0px;
      border-left:1px solid #fff;
      .nav-item {
        .dropdown-item {
          color:white;
          &:hover {
            background:$dark;
            color:white;
          }
        }
      }
    }
  }
}
