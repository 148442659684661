.is-mega {
  position: relative;
  .navbar-collapse > div {
    position:relative;
  }
  .has-mega-menu {
    position: unset;
    .dropdown-menu {
      .mega-title {
        flex-basis: 100% !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
        padding-bottom: 10px !important;
        margin-bottom: 10px !important;
        a {
          font-size: 24px;
          text-transform: none;
          color: $accent;
          &:hover {
            background: transparent !important;
            color:$accent;
          }
        }
        @include media-breakpoint-down(xl) {
          display: none !important;
        }
      }
    }
    &.dropdown:last-child {
      @include media-breakpoint-up(xl) {
        .dropdown-menu {
          left: auto!important;
        }
      }
    }
  }
  // specific styles
  &.mega-full-background {
    .dropdown-menu {
      background: transparent;
      display: block;
    }
    .mega-menu-image {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &.mega-partial-background {
    .has-mega-menu {
      &:hover {
        .dropdown-menu {
          display:flex;
        }
      }
      .dropdown-menu {
        background: linear-gradient(90deg, $brand-primary 60%, transparent 40%);
        padding-right: 50%;
        flex-flow: wrap;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left:20px;
        display: none;
        left:auto!important;
        right:25px!important;
        width:950px!important;

        &.show {
          display: flex;
        }
        li {
          flex-basis: 50%;
          border-bottom: 0;
          padding-right: 20px;
        }
      }
      .mega-menu-image {
        position: absolute;
        width: 40%;
        height: 100%;
        right: 0;
        z-index: -1;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

.mega-title {
  @include media-breakpoint-down(xl) {
    display: none !important;
  }
}
