body {
  background: #fff;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes
//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */
.invert-img img {
  filter: invert(1) grayscale(1);
}
.clip-right {
  clip-path: polygon(0px 0px, 80% 0%, 100% 100%, 0px 100%);
}
.clip-left {
  clip-path: polygon(20% 0px, 100% 0%, 100% 100%, 0px 100%);
}
.cp-0 {
  padding-left:0px;
  padding-right:0px;
  .row {
    margin-left:0px;
    margin-right:0px;
  }
}
.border-radius {
  border-radius: 0px;
}
.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 185px!important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
.splide__pagination {
  bottom: -45px;
}
