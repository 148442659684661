/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper .gfield input.large, .gform_wrapper .gfield select.large, textarea, .name_first input, .name_last input, .gform_legacy_markup_wrapper .ginput_complex .ginput_left input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex .ginput_right input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex .ginput_right select, .gform_legacy_markup_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex select, .gform_legacy_markup_wrapper .ginput_complex .ginput_left input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex .ginput_right input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex .ginput_right select, .gform_legacy_markup_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper .ginput_complex select {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #bfbfbf !important;
  outline: none !important;
  border-radius: 0px !important;
  padding: 15px !important;
}
.gform_wrapper input[type="submit"] {
  background-color: $brand-primary;
  color:#fff;
  border: 0px;
  border-radius: 0px;
  padding: 1em 2em;
  font-family: $heading-font;
  text-transform:uppercase;
}
