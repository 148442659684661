@media (max-width: 1799px) {
	h1.h4,
	h1.h4 small,
	h2.h3,
	h3.h5,
	h5.secondary {
		display: none;
	}
}

h1.h4 {
	font-size: 1.1rem;
	background: $brand-primary;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
	padding-top:30px;
	color:#fff;
}

h2.h3 {
	font-size: 1.1rem;
	background: $brand-primary;
	padding: 10px;
	text-align: center;
	font-weight: 500;
	margin-bottom:0;
	color:#fff;
}

h3.h5 {
	font-size: 1.1rem;
	background: $brand-primary;
	color: #fff;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
}
.page-container h2.h3 {
	text-align: left;
	background: transparent;
}
.btn[data-target="#secondaryGeos"] {
    border-radius: 0px;
    width: 100%;
	display:none;
}
@media (min-width:5000px) {
	.btn[data-target="#secondaryGeos"] {
		display: block;
	}
}
.main h1.h4 small:after {
	content: " and surrounding communities";
}

.hidden {
	display: none !important;
}

.bodyimgright,
.container img.bodyimgright,
.plugin-img,
.sidebar img.bodyimgright {
	float: right;
	max-width: 30%;
}

.seo {
	display: none;
}
@media (min-width:1800px) {
	.seo {
		display: block;
	}
}
[data-bs-target="#secondaryGeos"] {
	width: 100%;
}
#secondaryGeos {
	text-align:center;
	background:$brand-secondary;
	h4 {
		display: inline-block;
		margin: 15px;
		color:#fff;
	}
}
