.rpb-hero {
  z-index: 0;
  position: relative;
  //min-height: calc(75vh - 156px);
  display:flex;
  align-items:center;
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 55%;
    background: linear-gradient(145deg, #0178B2 0%, #005c89 100%);
    left: 0px;
    width:100%;

    @include media-breakpoint-up(md) {
      clip-path: polygon(0px 0px, 80% 0%, 100% 100%, 0px 100%);
      width:66%;
    }
    @include media-breakpoint-up(xl) {
      width: 55%;
    }
  }
  .row {
    width:100%;
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .hero-content {
    position:relative;
    z-index:1;
  }
  // Background Gallery
  .rpb-hero-gallery {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #222;

    .rpb-gallery-single {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      z-index: 0;
      opacity: 0;

      /** up to 10 slides
      animate class added in hero
      animation: #s ease infinite normal both running bg-fade-out;
      https://tympanus.net/codrops/2012/01/02/fullscreen-background-image-slideshow-with-css3/
      //set slide time interval in hero
      //10 slides max
      **/
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: calc(#{$i - 1} * 6s)!important;
        }
      }
    }
  }
  // CSS only background image fade slider
  //step 2: 6s / slides*seconds (6X6)  = 16.66
  //step 1: fade in 16.66/2 = 8.33
  //setp 3: 8.33 * 3 = 24.99
  @keyframes bg-fade-out {
    0% { opacity: 0; animation-timing-function: ease-in; }
    8% { opacity: 1; animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
  }
  // Video Background
  .absolute-slider,
  .hero-bkg-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101010;

    .slick-list,
    .slick-slide,
    .slick-track {
      width: 100%;
      height: 100%;

      * {
        height: 100%;
      }
    }
  }
  /** embed video styles**/
  .hero-video {
    overflow: hidden !important;

    .autoplay-video {
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translate(-50%, 0%) !important;
      -o-transform: translate(-50%, 0%) !important;
      transform: translate(-50%, 0%) !important;
      width: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }
  /** youtube video styles **/
  .inline-YTPlayer {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .hero-bkg-youtube-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Slider Styles
.rpb-hero-slider {
  .splide__slider {
    .splide__track {
      .splide__list {
        .splide__slide {
          padding-right: 4rem;
          padding-left: 4rem;
        }
      }
    }
  }
}
