/////////////////////////////////////
// Core Buttons
/////////////////////////////////////

.btn {
  border:0;
  border-radius:0;
  padding: 1rem 2rem;
  font-weight:400;
  color:white;
  position:relative;
  border:0!important;
  border-radius:0px!important;
  transition:all .2s ease-out;

  &:hover {
    color:white;
    border: 0!important;
    transition:all .2s ease-out;
  }
  &.btn-sm {
    padding: 0.45rem 1rem;
    font-size:13px;
  }
  &.btn-lg {
    padding: 1.25rem 3rem;
  }
  &.small {
    font-size: 16px;
  }
}
.header-topbar .btn.small {
  @include media-breakpoint-down(lg) {
    font-size: 13px!important;
  }
}
// Primary
/////////////////

.btn-primary {
  background: linear-gradient(135deg,$brand-secondary, $brand-secondary);
  &:hover {
    background: linear-gradient(135deg,$brand-secondary, $brand-primary);
  }
  &.transparent {
    background:transparent;
    border:3px solid $brand-primary;
    color:$brand-primary;
    &:hover {
      background:$brand-primary;
      color:white;
    }
  }
  &.gradient {
		background: darken($brand-primary, 15%);
		background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    }
	}
}

// Secondary
/////////////////

.btn-secondary {
  background: $brand-secondary;
  &:hover {
    background: lighten($brand-secondary, 10%);
  }
  &.transparent {
    background:transparent;
    border:3px solid $brand-secondary;
    color:$brand-secondary;
    &:hover {
      background:$brand-secondary;
      color:white;
    }
  }
  &.gradient {
		background: $brand-secondary;
		background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    }
	}
}

// Accent
/////////////////

.btn-accent {
  background: $accent;
  &:hover {
    background: lighten($accent, 10%);
  }
  &.transparent {
    background:transparent;
    border:3px solid $accent;
    color:$accent;
    &:hover {
      background:$accent;
      color:white;
    }
  }
  &.gradient {
		background: $accent;
		background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $accent 0%, darken($accent,15%) 100%);
    }
	}
}

// Light
/////////////////

.btn-light {
  background: $light;
  color:$dark;
  &:hover {
    background: lighten($light, 10%);
    color:$dark;
  }
  &.transparent {
    background:transparent;
    border:3px solid $light;
    color:$light;
    &:hover {
      background:$light;
      color:$dark;
    }
  }
  &.gradient {
		background: $light;
		background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $light 0%, darken($light,15%) 100%);
    }
	}
}

// Dark
/////////////////

.btn-dark {
  background: $dark;
  color:white;
  &:hover {
    background: lighten($dark, 10%);
  }
  &.transparent {
    background:transparent;
    border:3px solid $dark;
    color:$dark;
    &:hover {
      background:$dark;
      color:white;
    }
  }
  &.gradient {
		background: $dark;
		background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $dark 0%, darken($dark,15%) 100%);
    }
	}
}

// White
/////////////////

.btn-white {
  background: white;
  color:black;
  &:hover {
    background: lighten(white, 10%);
    color:black;
  }
  &.transparent {
    background:transparent;
    border:3px solid white!important;
    color:white;
    &:hover {
      background:white;
      color:black;
    }
  }
}

// Black
/////////////////

.btn-black {
  background: black;
  color:white;
  &:hover {
    background: lighten(black, 10%);
  }
  &.transparent {
    background:transparent;
    border:3px solid black;
    color:black;
    &:hover {
      background:black;
      color:white;
    }
  }
  &.gradient {
		background: #000;
		background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, #000 0%, darken(#000,15%) 100%);
    }
	}
}

.btn-gradient {
  .btn-primary {
    &:not(.transparent) {
      background: linear-gradient(145deg, $brand-primary 0%, $brand-primary);
		  background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    }
  }
  .btn-secondary {
    &:not(.transparent) {
      background: $brand-secondary;
		  background: linear-gradient(145deg, $brand-secondary 0%, lighten($brand-secondary,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    }
  }
  .btn-accent {
    &:not(.transparent) {
      background: $accent;
		  background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $accent 0%, darken($accent,15%) 100%);
    }
  }
  .btn-light {
    &:not(.transparent) {
      background: $light;
		  background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $light 0%, darken($light,15%) 100%);
    }
  }
  .btn-dark {
    &:not(.transparent) {
      background: $dark;
		  background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $dark 0%, darken($dark,15%) 100%);
    }
  }
  .btn-black {
    &:not(.transparent) {
      background: #000;
		  background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, #000 0%, darken(#000,15%) 100%);
    }
  }
}
