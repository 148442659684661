.site-footer {
	.social a {
		height: 50px;
		width: 50px;
		background: rgba(255,255,255,0.1);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 50px;
	}
	img {
		width:auto;
		max-height:80px;
	}
	.footer-main {
		i,svg {
			min-width:30px;
			color:white;
		}
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: white;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
}